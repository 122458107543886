import React from "react"

import Layout from "layout"

const Registration = (props) => {
  const { pageContext } = props

  return (
    <Layout
      pageContext={pageContext}
      display={{ helpCenterBanner: false, footer: false }}
      seoTitle="Foundever | PRIME"
      isWellness={true}
    >
      <iframe
        id=""
        allowTransparency="true"
        allowFullScreen="true"
        allow="geolocation; microphone; camera"
        src={process.env.GATSBY_PRIME_FORMS_APP_REGISTRATION_LINK}
        style={{
          width: "90vw",
          minWidth: "100%",
          height: "90vh",
          border: "none",
        }}
      ></iframe>
    </Layout>
  )
}

export default Registration
